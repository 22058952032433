<template>
  <ClientOnly>
    <div v-if="false" />
  </ClientOnly>
</template>
<script lang="ts" setup>
import { EGTMEnabledFlags } from 'shared/enums/gtmEnabledFlags.enum';
// TODO 29.07.2022 - решить, что делать с noscript, который рендерит внутренности строкой
// https://maxmart.notion.site/GTM-9b4ffd8b4226475a9b17f47b8a2b5a7d

const targetScript = [
  {
    hid: 'gtm',
    children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MGBPSJN');
    `,
    type: 'text/javascript',
  },
];
const isGTMEnabled = computed(() => useRuntimeConfig().public?.gtmEnabled === EGTMEnabledFlags.Enabled); // eslint-disable-line
const currentScript = computed(() => isGTMEnabled.value ? targetScript : []);
useHead({
  script: currentScript.value,
});
</script>
